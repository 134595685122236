import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';

const Index = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const skills = [
        { name: 'JavaScript', icon: 'javascript' },
        { name: 'TypeScript', icon: 'typescript' },
        { name: 'React', icon: 'react' },
        { name: 'Node.js', icon: 'node-dot-js' },
        { name: 'Python', icon: 'python' },
        { name: 'MongoDB', icon: 'mongodb' },
    ];

    const projects = [
        {
            title: 'AI Bot for Discord',
            description: 'A Powerful AI bot for Discord, / commands using Hugging Face API, developed with Node.js.',
            github: 'https://github.com/onecvi/discord-ai-bot',
            technologies: ['Node.js'],
        },
        {
            title: 'Monitor - Ritel V1 (Coming Soon)',
            description: 'The ultmate monitoring tool for your website, get real-time data and alerts.',
            github: 'https://github.com/184x/ritel',
            technologies: ['React', 'Firebase', 'TypeScript'],
            live: ''
        },
    ];

    return (
        <div className="font-sans min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
            {/* Navigation */}
            <nav className="fixed w-full bg-gray-900 bg-opacity-90 z-50 px-6 py-4">
                <div className="flex justify-between items-center">
                    <span className="text-white text-xl font-bold">l184</span>
                    <div className="md:hidden">
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-white">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
                            </svg>
                        </button>
                    </div>
                    <div className={`${isMenuOpen ? 'block' : 'hidden'} md:block`}>
                        <div className="flex flex-col md:flex-row md:space-x-8 space-y-4 md:space-y-0">
                            <a href="#about" className="text-white hover:text-blue-400 transition-colors">About</a>
                            <a href="#skills" className="text-white hover:text-blue-400 transition-colors">Skills</a>
                            <a href="#projects" className="text-white hover:text-blue-400 transition-colors">Projects</a>
                            <a href="#contact" className="text-white hover:text-blue-400 transition-colors">Contact</a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Header and Introduction */}
            <header className="text-center pt-32 pb-16 bg-gray-800 text-white">
                <div className="relative inline-block">
                    <img src="https://i.ibb.co/vYhcWbV/032f710f9f584acded7bd5fdff6369af.jpg" 
                         alt="l184's Profile" 
                         className="w-32 h-32 rounded-full mx-auto mb-4 shadow-xl hover:scale-105 transition-transform duration-300" />
                </div>
                <h1 className="text-5xl font-bold mb-2 animate-fade-in">Hello, I'm l184</h1>
                <p className="text-xl text-gray-400">Full-Stack Developer | TypeScript & JavaScript Expert</p>
                <p className="text-md mt-4 text-blue-500">A software developer passionate about creating innovative solutions</p>
                <div className="mt-8 space-x-4">
                    <a href="#contact" className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors">Contact Me</a>
                    <a href="#projects" className="border border-blue-600 text-blue-600 px-6 py-2 rounded-full hover:bg-blue-600 hover:text-white transition-colors">View Projects</a>
                </div>
            </header>

            {/* About Section */}
            <section id="about" className="py-16 px-6 md:px-20 bg-gray-900 text-white">
                <h2 className="text-3xl font-semibold mb-4 border-b-2 border-blue-500 pb-2 inline-block">About Me</h2>
                <div className="grid md:grid-cols-2 gap-8 mt-8">
                    <div>
                        <p className="text-lg text-gray-300 leading-relaxed">
                            I am a passionate software developer specialized in creating efficient and scalable solutions. 
                            With expertise in modern web technologies, particularly TypeScript and JavaScript, 
                            I bring ideas to life through clean, maintainable code.
                        </p>
                        <div className="mt-6">
                            <h3 className="text-xl font-semibold mb-2">Experience Highlights:</h3>
                            <ul className="list-disc list-inside text-gray-300">
                                <li>5+ years of professional development experience</li>
                                <li>Led multiple successful project deployments</li>
                                <li>Strong focus on clean code and best practices</li>
                                <li>Experienced in agile development methodologies</li>
                            </ul>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="bg-gray-800 p-4 rounded-lg">
                            <h3 className="text-xl font-semibold mb-2">Education</h3>
                            <p className="text-gray-300">Computer Science, BS</p>
                            <p className="text-gray-400">MIT, 2018-2022</p>
                        </div>
                        <div className="bg-gray-800 p-4 rounded-lg">
                            <h3 className="text-xl font-semibold mb-2">Certificates</h3>
                            <ul className="text-gray-300">
                                <li>AWS Certified Developer</li>
                                <li>Microsoft Certified: Azure Developer</li>
                                <li>IBM Data Science Professional Certificate</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* Skills Section */}
            <section id="skills" className="py-16 px-6 md:px-20 bg-gray-800 text-white">
                <h2 className="text-3xl font-semibold mb-4 border-b-2 border-blue-500 pb-2 inline-block">My Skills</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 mt-8">
                    {skills.map((skill, index) => (
                        <div key={index} className="text-center p-4 bg-gray-900 rounded-lg hover:transform hover:scale-105 transition-all duration-300">
                            <img 
                                src={`https://cdn.jsdelivr.net/npm/simple-icons@v5/icons/${skill.icon}.svg`} 
                                alt={skill.name} 
                                className="logo mx-auto w-12 h-12 filter invert opacity-75" 
                            />
                            <p className="mt-2 text-lg">{skill.name}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Projects Section */}
            <section id="projects" className="py-16 px-6 md:px-20 bg-gray-900 text-white">
                <h2 className="text-3xl font-semibold mb-4 border-b-2 border-blue-500 pb-2 inline-block">My Projects</h2>
                <div className="grid md:grid-cols-2 gap-8 mt-8">
                    {projects.map((project, index) => (
                        <div key={index} className="bg-gray-800 rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-2xl font-bold">{project.title}</h3>
                            <p className="mt-2 text-gray-400">{project.description}</p>
                            <div className="mt-4 flex flex-wrap gap-2">
                                {project.technologies.map((tech, i) => (
                                    <span key={i} className="px-3 py-1 bg-gray-700 rounded-full text-sm">
                                        {tech}
                                    </span>
                                ))}
                            </div>
                            <div className="mt-4 space-x-4">
                                <a href={project.github} target="_blank" rel="noopener noreferrer" 
                                   className="text-blue-500 hover:text-blue-400 transition-colors">
                                    GitHub
                                </a>
                                <a href={project.live} target="_blank" rel="noopener noreferrer" 
                                   className="text-green-500 hover:text-green-400 transition-colors">
                                    Live Demo
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact" className="py-16 px-6 md:px-20 bg-gray-800 text-white">
                <h2 className="text-3xl font-semibold mb-4 border-b-2 border-blue-500 pb-2 inline-block">Contact Me</h2>
                <div className="mt-8 flex flex-col md:flex-row gap-8">
                    <div className="md:w-1/2">
                        <h3 className="text-xl font-semibold mb-4">Get in touch</h3>
                        <p className="text-gray-300">Feel free to reach out for collaborations or just a friendly hello</p>
                        <div className="mt-4 space-y-2">
                            <p className="flex items-center">
                                <span className="mr-2">📧</span>
                                <a href="mailto:fera@astrauptime.com" className="text-blue-500 hover:text-blue-400">
                                    fera@astrauptime.com
                                </a>
                            </p>
                            <p className="flex items-center">
                                <span className="mr-2">💼</span>
                                <a href="https://discord.com/users/115933106061574147" target="_blank" rel="noopener noreferrer" 
                                   className="text-blue-500 hover:text-blue-400">
                                    Discord Profile
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="py-8 px-6 bg-gray-900 text-center text-gray-400">
                <p>© 2024 l184. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Index;
