import React from 'react';
import Index from './components/Index';

function App() {
  return (
    <div className="App bg-gray-900 text-white min-h-screen">
      <Index />
    </div>
  );
}

export default App;
